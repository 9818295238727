import { Item } from '../../type';

export const burgomaster: Item = {
  label: 'Бургомистр',
  count: 1,
  element: (
    <>
      <div>Талантливый управленец, назначенный главой города из столицы чуть более года назад.</div>
      <div>Его опыт и способности - то, что надо для выживания осаждённому городу перенесшему природное бедствие.</div>
    </>
  ),
}