import { Item } from '../type';

import s from './actions.module.css';

export const lockPick: Item = {
  label: 'Вскрытие сундука',
  element: (
    <>
      <div>Игрок может вскрыть игровой сундук с целью получения содержимого.</div>

      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок находит сундук.</li>
        <li>Игрока приносит сундук мастеру или приглашает мастера на место обнаружении сундука.</li>
        <li>Игрок использует <b>«Ремкомплект»</b>.</li>
        <li>Игрок получает отмычки и 1 минуту на вскрытие замка.</li>
        <li>В случае успеха игрок может забрать все содержимое.</li>
        <li>В случае провала игрок может повторить попытку за еще один <b>«Ремкомплект»</b>.</li>
      </ol>

      <div className={s.label}><i>Запрещается</i></div>
      <ol className={s.listContainer}>
        <li>Использовать свои отмычки.</li>
        <li>Целенаправленно ломать реквизит.</li>
        <li>Вскрывать замок без присутствия мастера.</li>
        <li>Вскрывать не игровые сундуки.</li>
      </ol>
    </>
  ),
}