import { Item } from '../rules-text/type';

import tudorCoat from '../assets/coats/tudor.png';

import s from './history.module.css';

export const tudorHistory: Item = {
  label: 'Империя Тюдор',
  element: (
    <>
      <div className={s.info}>
        <div className={s.coat}>
          <img src={tudorCoat} alt='' />
        </div>

        <div className={s.text}>
          <div><i>Правитель: </i>император Фердинад</div>
          <div><i>Столица: </i>город Голберг</div>
          <div><i>Герб: </i>Красный дракон на черном фоне</div>
          <div><i>Цвета: </i>Императорские цвета: красный и черный едины для всех государственных служащих</div>
        </div>
      </div>

      <div className={s.block2}>
        <h3>Краткая история</h3>
        <div>После разрушения империи Гейзериха большинство имперской аристократии бежали на территорию нынешней империи Тюдор</div>
        <div>Смерть Гейзериха была объявлена точкой в королевской династии, поэтому был избран новый император, от которого начался род текущей династии правителей</div>
        <div>Тюдорская аристократия мечтает вернуться в свою столицу и возродить величие империи</div>
        <br/>
        <div>Поводом для конфликта между Мидландом и Тюдор является не признание последними королевской линии Мидленда, и как следствие оспаривание территории самого королевства</div>
        <div>Тюдорская империя является одним из активных пользователей пороховой артиллерии</div>
      </div>
    </>
  ),
}