import React from 'react';

import { AccordionBlock, Chapter, Video } from '../../../components';

import s from './plague.module.css';

export function Plague(): JSX.Element {
  return (
    <>
      <div className={s.container}>
        <Chapter chapter='ЧУМА И БОЛЕЗНИ' />
        <AccordionBlock
          label="Район и лагерь"
          items={[
            {
              label: 'Уровень заражения района',
              element: (
                <>
                  <div>У каждого района на старте есть свой общий уровень заражения по шкале от 1 до 10.</div>
                  <div>Лагеря наемников так же считаются районами.</div>
                  <div>Если был достигнут максимально допустимый уровень заражения района, то района закрывается, а все игровые процессы в нем останавливаются (смотри <b>«Падение района»</b>).</div>
                  <div>Уровень заражения отслеживает регионал данного района.</div>
                  <div>На уровень заражения района влияют только маркеры чумы, размещенные на локациях, личные маркеры заражения персонажа не влияют на уровень заражения района.</div>
                  <div>Чтобы повысить максимально допустимый уровень заражения можно применить действие <b>«Профилактика инфекций»</b>.</div>
                  <div>Что бы убрать одну единицу заражения района или строения необходимо применить действие <b>«Устранение инфекций»</b>.</div>
                  <div>Маркеры заражения могут быть размещены различными способами на строении, при обнаружении такого маркера игрок может самостоятельно его убрать, считается что инфекция была быстро обнаружена и устранена самостоятельно.</div>
                  <div>Уровень заражения определенного района храниться у мастера «в закрытую», чтобы узнать текущий уровень заражения необходимо провести действие <b>«Исследование инфекций»</b>.</div>
                </>
              ),
            },
            {
              label: 'Падение района',
              element: (
                <>
                  <div>В случае если у района был достигнут максимальный уровень заражения, то:</div>
                  <ol className={s.listContainer}>
                    <li>Все игровые действия в районе заканчиваются.</li>
                    <li>Все игроки в течении 15 минут должны покинуть зараженный район, если они этого не успели сделать, то они получают маркер <b>«Заражен»</b>.</li>
                    <li>При посещении района уже зараженным персонажем, он получает еще один маркер <b>«Заражен»</b> вместе с травмой <b>«Кровотечение»</b> (смотри <b>«Тяжелая болезнь»</b>).</li>
                    <li>Для безопасного посещения района игрок должен быть снаряжен защитой дыхательных путей, перчаток, и не иметь открытых участков кожи (глаза в том числе). В случае плохой защиты игрок рискует получить маркер <b>«Заражен»</b>.</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Профилактика инфекций',
              element: (
                <>
                  <div>Медик может усилить устойчивость района или лагеря к заражению, повышая тем самым максимальный порог уровня заражения района (не более 15 уровня).</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li>Медика заявляет мастеру о проведении профилактических мер.</li>
                    <li>Медик производит манипуляции в районе вместе с игроками (поиск грызунов, сожжение трупов, просыпание улиц специальными лекарственными травами, общие прививки, развешивание чеснока и т.д.).</li>
                    <li>В зависимой от качества отыгрыша игрокам выставляет счет в количестве <b>«Медкомплектов»</b>.</li>
                    <li>Игроки оплачивают установленный ценник.</li>
                    <li>Мастер убирает определенное им количество маркеров заражения района.</li>
                  </ol>
                  <div className={s.label}>Дополнительно</div>
                  <div>На ценник влияют следующие показатели:</div>
                  <ol className={s.listContainer}>
                    <li>Чем больше лагерь район – тем дороже стартовый ценник.</li>
                    <li>Чем больше материалов применено во время отыгрыша – тем меньше ценник.</li>
                    <li>Чем больше людей задействовано в отыгрыше – тем меньше ценник</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Устранение инфекций',
              element: (
                <>
                  <div>Медки может заявить уменьшение уровня заражения района, используя свои маркеры <b>«Исцелен»</b>.</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li>Медик заявляет мастеру, что собирается понизить общий уровень заражения района.</li>
                    <li>Медик сдает маркеры <b>«Исцелен»</b> по формуле (3 маркера = - 1 уровень заражения района).</li>
                    <li>Мастер фиксирует изменения уровня заражения района.</li>
                  </ol>
                  <div className={s.label}>Дополнительно</div>
                  <div>На ценник влияют следующие показатели:</div>
                  <ol className={s.listContainer}>
                    <li>Чем больше лагерь район – тем дороже стартовый ценник.</li>
                    <li>Чем больше материалов применено во время отыгрыша – тем меньше ценник.</li>
                    <li>Чем больше людей задействовано в отыгрыше – тем меньше ценник</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Исследование инфекций',
              element: (
                <>
                  <div>Текущий уровень заражения района знает только его регионал. Чтоб получить данные о заражении района медик может заявить исследование инфекций.</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li>Медик заявляет мастеру действие <b>«Исследование инфекций»</b>.</li>
                    <li>Медик сдает мастеру 1 маркер <b>«Исцелен»</b>.</li>
                    <li>. Регионал говорит медику текущий уровень заражения района.</li>
                  </ol>
                </>
              ),
            },
          ]}
        />

        <AccordionBlock
          label="Персонаж"
          items={[
            {
              label: 'Заражение',
              element: (
                <>
                  <div>В течении игры персонаж рискует получить маркер <b>«Заражен»</b>, который говорит о том, что у него прослеживаются симптомы болезни.</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li>В паспорт игрока вклеивается маркер <b>«Заражен»</b>.</li>
                    <li>Игрок наугад вытягивает неактивную травму из своей колоды травм, с этого момента эта травма вызвана болезнью.</li>
                  </ol>
                  <div className={s.label}>Дополнительно</div>
                  <ol className={s.listContainer}>
                    <li>Чтобы снять маркер <b>«Заражен»</b> необходимо применить действие <b>«Лечение болезни»</b>.</li>
                    <li>Если игрок попал в мертвяк с маркером <b>«Заражен»</b>, то он получает +1 час мертвяка.</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Тяжелая форма заражения',
              element: (
                <>
                  <div>Если игрок уже имел маркер <b>«Заражен»</b> и при этом он получает новый маркер <b>«Заражен»</b>, то считается что болезнь приняла тяжелую форму.</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li>В паспорт игрока вклеивается второй маркер <b>«Заражен»</b>.</li>
                    <li>Игрок получает травму <b>«Кровотечение»</b>, которая будет снята в случае применения действия <b>«Лечение болезни»</b>.</li>
                  </ol>
                  <div className={s.label}>Дополнительно</div>
                  <ol className={s.listContainer}>
                    <li>«Лечение болезни» примененное к тяжелой форме заражения снимает только последний маркер <b>«Заражения»</b>.</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Лечение болезни',
              element: (
                <>
                  <div>Определенный тип докторов может излечить болезнь.</div>
                  <div>Врач, обладающий соответствующим антуражем, явно демонстрирующим что он специализируется на лечении болезней и инфекций.</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li>Врач просит игрока прилечь и описать симптомы болезни.</li>
                    <li>Врач просит игрока продемонстрировать свой паспорт игрока с вклеенным маркером <b>«Заражен»</b>.</li>
                    <li>Врач поверх вклеенного маркера вклеивает маркер <b>«Исцелен»</b>.</li>
                    <li>Травма, которая сопровождалась болезнью с этого момента убирается.</li>
                    <li>На 15 минут у пациента появляется травма <b>«Истощение»</b>.</li>
                    <li>По прошествии 15 минут игрок убирает травму <b>«Истощение»</b>.</li>
                  </ol>
                  <div className={s.label}>Примечание</div>
                  <ol className={s.listContainer}>
                    <li>Если <b>«Лечение болезни»</b> было применено 2 раза в рамках полного излечения от <b>«Тяжелой формы заражения»</b>, то временная травма <b>«Истощение»</b> не накладывается друг на друга.</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Создание лекарства',
              element: (
                <>
                  <div>Врач, профилирующийся на инфекциях и заражениях, может заявить это действие, чтобы получить маркеры <b>«Исцелен»</b>, которые он может в последствии использоваться для лечения пациентов.</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li style={{ alignItems: 'center' }}>
                      <div>Проводит эксперимент «Зубная паста для слона» в небольших объемах.</div>
                      <div><b>ВАЖНО:</b> Опыт необходимо проводить в перчатках и очках – выделяется много тепла. Желательно до мероприятия дома провести несколько экспериментов чтоб понять сколько выделяется пены и насколько сложно ее убирать.</div>
                      <div style={{ marginLeft: -160, padding: 15 }}>
                        <Video
                          src='https://vkvideo.ru/video_ext.php?oid=643853031&id=456265463&hd=2'
                          title='Опыт "Зубная паста для слона"'
                          width={360}
                        />
                      </div>
                    </li>
                    <li> Медик сдает ресурс <b>«Медкомплект»</b> по формуле (3 <b>«Медкомплекта»</b> = 1 маркер <b>«Исцелен»</b>).</li>
                  </ol>

                  <div className={s.label}>Примечание</div>
                  <ol className={s.listContainer}>
                    <li>Если <b>«Лечение болезни»</b> было применено 2 раза в рамках полного излечения от <b>«Тяжелой формы заражения»</b>, то временная травма <b>«Истощение»</b> не накладывается друг на друга.</li>
                  </ol>
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  )
}