import { Item } from '../type';

import s from './actions.module.css';

export const theft: Item = {
  label: 'Воровство',
  element: (
    <>
      <div>Воровство - отличный способ разбогатеть за счет других.</div>
      <div className={s.label}>Карта <b>"Воровство"</b></div>
      <ol className={s.listContainer}>
        <li>Каждому игроку по востребованию будет выдано некоторое количество карт воровства (не более трех в руки).</li>
        <li>Дополнительные карты воровства можно купить у мастерского торговца за деньги.</li>
        <li>Карта не является побиваемым предметом и НЕ предъявляется при любом виде обыска.</li>
        <li>Наличие таких карт в паспорте игрока игрока не делает его вором.</li>
        <li>Карты воровства, которые не использованы, но могут быть использованы игроков в будущем храниться исключительно в паспорте игрока, чтоб их нельзя было спутать с примененными картами</li>
        <li>Сохраняется после смерти.</li>
      </ol>

      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок-вор должен подкинуть в сумку к жертве карту «Воровство» (именно в сумку или кошель, не в капюшон, не в рубашку и т.д.).</li>
        <li>Игрок-вор сообщает мастерскому торговцу (или интенданту лагеря) о том, что произвел кражу и однозначно описывает персонажа и в какую сумку он подбросил карту.</li>
        <li>Мастерский торговец просит игрока-жертву показать карту воровства, из места которое указал игрок-вор.</li>
        <li>В случае обнаружения такой карты мастерский торговец просит показать игровые ресурсы из паспорта игрока, и забирает тот который считает нужным.</li>
        <li>Игрок-вор в любое удобное время посещает мастерского торговца за наградой в виде ресурса, который тут же можно и продать.</li>
      </ol>
      <div>ПРИМЕЧАНИЕ: Таким образом могут быть сворованы и игровые ценности, и зелья, но вор должен указать что ворует именно их, в случае если у игрока таких предметов нет - для вора ничего не происходит.</div>

      <div className={s.label}><i>Дополнительно</i></div>
      <div className={s.listContainer}>
        <li>Вор может воровать только игровые предметы.</li>
        <li>Вор не может воровать игровые деньги, вместо этого он может продавать ворованные игровые предметы.</li>
        <li>Любой игрок-человек может попробовать что-то украсть.</li>
        <li>Воровство – грех.</li>
      </div>
    </>
  ),
}