import { NavLink } from 'react-router-dom';

import {
  Chapter,
  AccordionBlock, CharStatus,
} from '../../../components';

import {
  fullArmor, heavyArmor, lightArmor,
  midArmor, noArmor,
} from '../../../rules-text/examples/armor';

import { hiArmor } from '../../../rules-text/examples/armor/hi-quality';
import batZones from '../../../assets/battle-zones.png';
import heart from '../../../assets/icons/health/heart.png';
import shield from '../../../assets/icons/health/shield.png';

import deadStripe from '../../../assets/rules/status/dead-status.png';
import healStripe from '../../../assets/rules/status/heal-stripe.png';
import repairStripe from '../../../assets/rules/status/repair-stripe.png';

import s from './battle.module.css';

export function Battle(): JSX.Element {
  return (
    <>
      <div className={s.container}>
        <Chapter chapter='БОЕВЫЕ ВЗАИМОДЕЙСТВИЯ' />
        <div>На игре представлен классический средневековый арсенал вооружения, кроме ручного огнестрельного оружия.</div>

        <AccordionBlock
          label='Общие положения'
          items={[
            {
              label: 'Боевые зоны игрока',
              element: (
                <div className={s.zones}>
                  <img src={batZones} alt='' height={300} width={150}/>

                  <div className={s.zonesText}>
                    <div><b style={{ color: 'green' }}>Зеленые зоны</b> - поражаемые зоны. Попадание в эти зоны протектированного оружия снимает хиты персонажа.</div>
                    <div><b style={{ color: 'red' }}>Красные зоны</b> - запрещённые к поражению зоны. Намеренное попадание оружием в эти зоны строжайше запрещено.</div>
                    <br />
                    <div>
                      Поражаемой зоной чудовищ считается вся передняя плоскость костюма, кроме части костюма, где располагается голова оператора. При этом костюмы должны быть выполнены таким образом, чтобы защитить все запрещенные к поражению зоны оператора с какой бы стороны не наносился урон.
                    </div>
                  </div>
                </div>
              ),
            },
            {
              label: 'Ограничения в бою',
              element: (
                <>
                  <div className={s.label}><i>Ближний бой</i></div>
                  <ol className={s.listContainer}>
                    <li>Запрещается использовать чужое оружие без разрешения хозяина.</li>
                    <li>Запрещается наносить удары щитом, удары небоевой частью оружия и акцентированные колющие удары не допущенным для этого оружием.</li>
                    <li>Запрещается бить и толкать оппонента руками, ногами или телом, совершать захваты, заломы и броски.</li>
                    <li>Запрещается зажимать или захватывать оружие/щит противника.</li>
                    <li>Запрещается атаковать противника, который не видит этого, с разбега со спины.</li>
                    <li>Запрещается пытаться опрокинуть противника или сбить его с ног любым образом.</li>
                    <li>Запрещено наносить акцентированные удары по противнику, который сдался/умер/сел.</li>
                  </ol>

                  <div className={s.label}><i>Дистанционный бой</i></div>
                  <ol className={s.listContainer}>
                    <li>Запрещается стрелять вслепую: всегда выбирайте конкретную цель.</li>
                    <li>Запрещается стрелять на дистанции ближе чем: 3 метра для дистанционного оружия, 5 метров при стрельбе по людям из тяжелого оружие.</li>
                    <li>Запрещается стрелять из луков и арбалетов по щитам или укреплениям, когда рядом с ними, по вашу сторону в 3х метрах или ближе находятся другие игроки, поскольку стрела может от рикошетить твёрдым хвостовиком и нанести травму.</li>
                    <li>Запрещается стрелять, если видны в основном запрещенные к поражению зоны (голова, кисти, стопы).</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Боевая ситуация',
              element: (
                <>
                  <div>Боевой ситуаций считается взаимодействие двух или более игроков посредством обмена ударами протектированным оружием или/и атака разрешенным метательным оружием. Боевая ситуация считается завершенной, когда одна из сторон одержала очевидную победу над другой, или было достигнуто соглашение о прекращении боевых взаимодействий.</div>
                  <div>Боевая ситуация считается оконченной, когда в радиусе 10 метров не ведётся боевых взаимодействий.</div>

                  <div className={s.label}><i>Дополнительно</i></div>
                  <div className={s.listContainer}>
                    <li>Разрешается отползти от эпицентра боестолкновения, лежа/сидя закрывая себя рукам/щитом.</li>
                    <li>Запрещается вставать до окончания боевой ситуации.</li>
                    <li>Запрещается любым способом мешать игрокам продолжать боевое столкновение..</li>
                  </div>
                </>
              ),
            },
            {
              label: 'Статус персонажа',
              element: (
                <>
                  <div>Переходы между статусами и снятие урона различным оружием можно проверить на нашей визуализации:</div>
                  <NavLink replace to='/help/heal-calc'>Проверить как работает боевая система</NavLink>

                  <CharStatus
                    label='Здоров'
                    model='Никак не маркируется'
                    play='Игрок с полным набором хитов'
                  />

                  <CharStatus
                    label='Ранен'
                    model='Никак не маркируется'
                    play='Игрок потерявший один или более броневых хитов'
                  />

                  <CharStatus
                    label='Тяжело ранен'
                    model='Никак не маркируется'
                    play='Игрок находится в лежачем или сидячем положении в течении 15 минут. Игрок не может сопротивляться игровым действиям других игроков. В случае не оказания медицинской помощи в течении 15 минут переходит в состояние "Мертв"'
                  />

                  <CharStatus
                    label='Мертв'
                    model='Белая повязка с черной печатью'
                    play='Этот персонаж не участвует в игровом процессе.'
                    img={deadStripe}
                  />

                  <CharStatus
                    label='Лечение'
                    model='Красная повязка с зеленой печатью'
                    play='Этот персонаж лечится. В случае получения урона переходит в состояние "Тяжело ранен".'
                    img={healStripe}
                  />

                  <CharStatus
                    label='Ремонт'
                    model='Серая повязка с красной печатью'
                    play='Снаряжение данного персонажа ремонтируется. В случае получения урона состояние "Ремонт" отменяется.'
                    img={repairStripe}
                  />
                </>
              ),
            },
          ]}
        />

        <AccordionBlock
          label='Хиты и хитосъем'
          items={[
            {
              label: 'Хиты персонажа',
              element: (
                <>
                  <div>Люди обладают 1 живым хитом, остальные хиты считаются броневыми.</div>
                  <div>Все хиты чудовища считаются живыми.</div>
                  <NavLink replace to='/help/char-calc'>Самостоятельно определить свои хиты</NavLink>
                  <br />
                  <div className={s.sub_container}>
                    <h3>{'Без доспеха'.toUpperCase()}</h3>
                    <div><img src={heart} alt='' width={30} /></div>

                    <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Примеры отсутствия брони</div>
                    {noArmor.element}
                  </div>

                  <div className={s.sub_container}>
                    <h3>{'Легкий доспех'.toUpperCase()}</h3>
                    <div>
                      <img src={heart} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                    </div>
                    <div>Обязательные элементы:</div>

                    <div className={s.listContainer}>
                      <li>Короткая (ниже талии, но не ниже колена) кольчуга</li>
                      <li>или бригантина, кожаный панцирь (из кожи толщиной не менее 3мм), латный, чешуйчатый или ламинарный нагрудник</li>
                    </div>

                    <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Примеры легкой брони</div>
                    {lightArmor.element}
                  </div>

                  <div className={s.sub_container}>
                    <h3>{'Средний доспех'.toUpperCase()}</h3>
                    <div>
                      <img src={heart} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                    </div>
                    <div>Обязательные элементы:</div>

                    <div className={s.listContainer}>
                      <li>Полная защита груди.</li>
                      <li>Несколько не парных элементов доспеха на конечностях.</li>
                    </div>

                    <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Примеры легкой брони</div>
                    {midArmor.element}
                  </div>

                  <div className={s.sub_container}>
                    <h3>{'Тяжелый доспех'.toUpperCase()}</h3>
                    <div>
                      <img src={heart} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                    </div>
                    <div>Обязательные элементы:</div>

                    <div className={s.listContainer}>
                      <li>Полная латная, чешуйчатая, кольчато-пластинчатая или ламинарная защита корпуса (с защитой спины)</li>
                      <li>Латные или иные наплечники</li>
                      <li>Латные или пластинчатые наручи</li>
                      <li>Латная, пластинчатая или иная защита бедра до колена</li>
                    </div>
                  </div>

                  <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Примеры тяжелой брони</div>
                  {heavyArmor.element}

                  <div className={s.sub_container}>
                    <h3>{'Полный доспех'.toUpperCase()}</h3>
                    <div>
                      <img src={heart} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                      <img src={shield} alt='' width={30} />
                    </div>
                    <div>Обязательные элементы:</div>

                    <div className={s.listContainer}>
                      <li>Полная латная, чешуйчатая, кольчато-пластинчатая или ламинарная защита корпуса (с защитой спины)</li>
                      <li>Полная латная или пластинчатая защита рук (наплечники+налокотники+наручи)</li>
                      <li>Полная латная, пластинчатая или иная защита ног до низа голени (защита бедра+наколенники+наголенники)</li>
                    </div>
                  </div>
                  <div><i>ПРИМЕЧАНИЕ: Чтоб ваши броневые хиты учитывались ОБЯЗАТЕЛЬНЫМ элементом любого комплекта считается шлем</i></div>

                  <div style={{ fontWeight: 'bold', marginTop: '10px' }}>Примеры полной брони</div>
                  {fullArmor.element}

                <br/>
                  <h3>{'Качество брони'.toUpperCase()}</h3>
                  <div>
                    <div>Доспехи бывают обычного и высокого качества, если ваш доспех не соответствуют следующим требованиям - он считается обычным.</div>
                    <div>За качество брони отдельным игрокам может быть начислен дополнительный броневой хит на регистрации, он учитывается только когда игрок носит полный комплект брони, который был продемонстрирован на регистрации.</div>

                    <div className={s.label}><i>Требования</i></div>
                    <div className={s.listContainer}>
                      <li>Все стандартные модели металлических доспехов в хорошем состоянии.</li>
                      <li>Габаритные доспехи повышенной эпичности.</li>
                      <li>Начисляется только в полном снаряжении.</li>
                    </div>
                    {hiArmor.element}
                  </div>
                </>
              ),
            },
            {
              label: 'Урон оружия',
              element: (
                <>
                  <div>Оружие <b>ближнего</b> боя снимает 1 хит, не пробивает щиты.</div>
                  <div>Оружие <b>дальнего</b> боя снимает 2 хита, не пробивает щиты.</div>
                  <div><b>Особое</b> оружие снимает 5 хитов, не пробивает щиты.</div>
                  <div><b>Пушка</b> снимает 10 хитов, пробивает щиты, может снимать очки прочности ворот.</div>Любой игровой предмет
                </>
              ),
            },
            {
              label: 'Особое оружие',
              element: (
                <>
                  <div>Данный вид оружия выдается строго определенному кругу персонажей, которых можно однозначно распознать внешне.</div>
                  <div>На самом оружии будет соответствующая метка. Особое оружие монстров не маркируется.</div>
                  <div>
                    <div className={s.label}>Оружие капитана лагеря</div>
                    <div>Требование: двуручное не колющее оружие ближнего боя</div>
                    <i>Капитан лагеря - это опытный боец, знающий все слабости креплений доспеха, поэтому его удары точны и смертоносны</i>
                  </div>

                  <div>
                    <div className={s.label}>Чудовища</div>
                    <div>Клыки, рога, шипы, когти, щупальца и т.д.</div>
                    <i>Чудовища - полумифические создания, обладающие нечеловеческой силой</i>
                  </div>
                </>
              ),
            },
            {
              label: 'Пушки',
              element: (
                <>
                  <div>Каждая пушка имеет 1 очко прочности (т.е. в бою ее можно уничтожить только прямым попаданием другой пушки).</div>
                  <div>
                    <div className={s.label}>Требования</div>
                    <ol className={s.listContainer}>
                      <li>Наличие расчёта в составе 3 человек.</li>
                        <div className={s.listContainer}>
                          <div>3 человека в расчёте – пушка не имеет ограничений.</div>
                          <div>2 человека в расчёте – пушка не может двигаться, но может стрелять.</div>
                          <div>0-1 человек в расчёте – пушка не стреляет и не двигается.</div>
                        </div>
                      <li>Наличие зарядного ящика или телеги в которой хранятся заряды.</li>
                        <div className={s.listContainer}>
                          <div>Снаряды для орудия могут храниться ТОЛЬКО в зарядном ящике (в ящик должны помещаться все заряды, запланированные при выезде).</div>
                          <div>Если зарядный ящик вне доступа пушки, то пушка не имеет права стрелять.</div>
                          <div>В ящике можно хранить ресурсы согласно правилам, так словно сами заряды не весят ничего.</div>
                        </div>
                      <li>В случае снятия очка прочности с пушки или окончания боезапаса, пушка обязана вернуться в лагерь на переподготовку.</li>
                    </ol>
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
    </>
  )
}