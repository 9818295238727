import { Item } from '../type';

import s from './actions.module.css';

export const knock: Item = {
  label: 'Оглушение',
  element: (
    <>
      <div>Защиту от оглушения дает только надетый шлем твердотельного корпуса.</div>
      <div className={s.label}><i>Механика:</i></div>
      <ol className={s.listContainer}>
        <li>Игрок хлопает не боевой частью оружия (яблоком меча/кинжала и т.д.) между лопаток игрока-жертвы.</li>
        <li>Игрок оповещает жертву о том, что та оглушена.</li>
        <li>Игрок-жертва в течении 5 минут не может сопротивляться чужим игровым действиям.</li>
        <li>После 5 минут игрок приходит в себя и не помнит, что происходило в течении 5 минут во время его оглушения.</li>
        <li>Игрок получает травму <b>«Мигрень»</b>.</li>
      </ol>

      <div className={s.label}><i>Дополнительно:</i></div>
      <ol className={s.listContainer}>
        <li>Оглушение не работает в боевой ситуации.</li>
        <li>Игрок-жертва не теряет хиты.</li>
        <li>Оглушение распространяется только на людей.</li>
      </ol>
    </>
  ),
}