import { Item } from '../../type';

export const peasants: Item = {
  label: 'Иные жители',
  element: (
    <>
      <div>Охотники, фермеры и прочие крестьяне (лимиты обсуждаются индивидуально).</div>
      <div>Простые местные жители, которым эта война нафиг не сдалась, и которые просто хотят спокойно жить, пить в кабаке и помереть от чахотки лет в тридцать.</div>
    </>
  ),
}