import React from 'react';

import { AccordionBlock, Chapter, ItemModal, LinkButton } from '../../../components';
import { randomizer } from '../../../rules-text/examples/randomizer';

import s from './priesthood.module.css';

export function Priesthood(): JSX.Element {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleClick = React.useCallback(() => {
      setIsOpen(true);
    }, []);

  return (
    <>
      <div className={s.container}>
        <Chapter chapter='ЖРЕЧЕСТВО' />

        <div className={s.label}>Общее</div>
        <div>В военных лагерях могут располагаться жрецы, которым будут доступны ритуалы, влияющие на игровой процесс.</div>
          <ol className={s.listContainer}>
            <li>В лагере может быть максимум один верховный жрец, этот титул может быть передан после смерти.</li>
            <li>Для проведения ритуала необходимо чтоб в лагере было установлено строение типа «Алтарь».</li>
            <li>В ходе игры жрец может изучить новые ритуалы и использовать их в своих целях. На старте же он обладает ограниченным количеством ритуалов.</li>
            <li>Все ритуалы проводятся возле алтаря в военном лагере, если Алтарь имеет уровень качества ноль, то проводить ритуалы возле него нельзя, пока не появиться хотя бы 1 звезда качества.</li>
            <li>Для проведения ритуала требуется обязательное присутствие интенданта и верховного жреца.</li>
          </ol>
        <AccordionBlock
          items={[
            {
              label: 'Сотворение ритуала',
              element: (
                <>
                  <div>Жрец проводит ритуал чтоб получить определенный эффект.</div>
                  <div className={s.label}>Механика</div>
                  <ol className={s.listContainer}>
                    <li>Жрец заявляет интенданту сотворение определенного ритуала.</li>
                    <li>Жрец передает интенданту необходимое подношение.</li>
                    <li>Верховный жрец возле алтаря проводит красивое представление хронометражем не менее 5 минут.</li>
                    <li>Пока проводится визуализация проведения ритуала, интендант высчитывает уровень успеха.</li>
                    <li>Жрец использует <LinkButton text='«Рандомайзер»' onclick={handleClick} /> для определения успеха ритуала.</li>
                    <li>В случае успеха интендант оглашает успешность верховному жрецу.</li>
                    <li>В случае провала интендант сообщает о провале, но не об его эффекте.</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Шансы на успех увеличиваются за счет',
              element: (
                <>
                  <ol className={s.listContainer}>
                    <li>Уровня качества постройки «Алтарь».</li>
                    <li>Количество участников, активно участвующих в ритуале.</li>
                    <li>Красота исполнения ритуала.</li>
                    <li>Добавленные подношения сверх необходимого минимума.</li>
                    <li>Антураж самих жрецов.</li>
                  </ol>
                </>
              ),
            },
            {
              label: 'Провал',
              element: (
                <>
                <div>В случае провала ритуала существует три варианта развития, которые случайным образом определяет сам интендант, в каждом ритуале будут прописаны все три пункта отдельно для простоты восприятия.</div>
                  <ol className={s.listContainer}>
                    <li>Ритуал просто не сработал. Ничего не произошло (Боги не услышали ваши молитвы).</li>
                    <li>Ритуал сработал не так как надо. (Боги частично услышали ваши молитвы, но поняли их по-своему)</li>
                    <li>Ритуал сработал в обратную сторону. (Боги разгневались).</li>
                  </ol>
                  <div>Дополнительно</div>
                  <ol className={s.listContainer}>
                    <li>Ритуал считается сорванным, если вовремя ритуала верховному жрецу помешали его провести или закончить.</li>
                    <li>В случае срыва ресурсы, потраченные на подношение, не возвращаются.</li>
                    <li>Порог <LinkButton text='«Рандомайзера»' onclick={handleClick} /> определяет мастер, но использует жрец во время ритуала.</li>
                    <li>Чем сложнее ритуал – тем больше стартовый порог провала.</li>
                    <li>В механике не предусмотрены случаи, когда ритуал имеет 100% гарантию успеха.</li>
                  </ol>
                </>
              ),
            },
          ]}
        />
      </div>

      {isOpen && (
        <ItemModal
          setIsOpen={setIsOpen}
          item={randomizer}
        />
      )}
    </>
  )
}