import { Item } from '../../type';

export const office: Item = {
  label: 'Канцелярия',
  count: 3,
  element: (
    <>
      <div>Обученные грамоте люди и мелкие чиновники, не желающие упускать возможность проявить себя и подняться по карьерной лестнице под крылом умелого руководителя.</div>
    </>
  ),
}