import { Item } from '../../type';

export const smiths: Item = {
  label: 'Кузнецы',
  count: 6,
  element: (
    <>
      <div>Ввиду того, что город живёт за счёт добычи и обработки руд - здесь располагается сразу несколько кузниц, непрерывно конкурирующих между собой за право доминировать на местном рынке.</div>
      <div>Разным кузням по-разному повезло при наводнении. Некоторые затопило полностью, а какие-то остались почти нетронутыми.</div>
    </>
  ),
}