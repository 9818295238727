import { Item } from '../type';

import s from './actions.module.css';

export const gateDamage: Item = {
  label: 'Разрушение ворот',
  element: (
    <>
      <div>Разрушение ворот может быть, как сюжетным, так и целенаправленным. Отличить одно от другого является одним из игровых моментов.</div>

      <div className={s.label}>Механика</div>
      <ol className={s.listContainer}>
        <li>Мастер фиксирует уменьшение прочности ворот.</li>
        <li>Если есть какой-то негативный эффект он его оглашает и вводит в игру.</li>
        <li>Каждое физическое попадание в стену или ворота снаряда осадной техники снимает с ворот 1 единицу прочности.</li>
      </ol>

      <table className={s.iksweb}>
        <tbody>
          <tr>
            <td>3 и выше очков прочности</td>
            <td>Не имеет побочных эффектов.</td>
          </tr>

          <tr>
            <td>2 очка прочности</td>
            <td>Второй этаж крепости закрыт для размещения войск</td>
          </tr>

          <tr>
            <td>1 очко прочности</td>
            <td>Одна створка ворот (на выбор мастера) открыта и зафиксирована.</td>
          </tr>

          <tr>
            <td>0 очков прочности</td>
            <td>Обе створки ворот открыты и зафиксированы.</td>
          </tr>
        </tbody>
      </table>
    </>
  ),
}