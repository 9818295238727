import icon from '../../../../assets/cards/icons/deck/harp.png';

import { Item } from '../../../type';

import s from '../../items.module.css';

export const artistDeck: Item = {
  label: 'Колода Артиста',
  icon: icon,
  element: (
    <>
      <div className={s.icon}><img src={icon} alt='' /></div>
      <div>Может находится только у артиста, прошедшего все фотодопуски (в паспорте игрока делается соответствующая пометка).</div>
      <div className={s.label}><i>Общее</i></div>
      <ol className={s.listContainer}>
        <li>Личный набор карт, которые смог собрать в течении игры артист, в ходе своих выступлений.</li>
        <li>В этой колоде могут хранится характерные для фаната психозы и карты <b>"Отдыха"</b>. Передавать или обменивать карты из этой колоды строжайше запрещено.</li>
        <li>Колоду нельзя отобрать во время обыска.</li>
        <li>Колода пропадает после смерти артиста.</li>
        <li>Пополняется действием <b>"Выступление"</b>.</li>
        <li>тратится на действие <b>"Автограф"</b>.</li>
      </ol>
    </>
  ),
}