import { Item } from '../../type';

export const traders: Item = {
  label: 'Представители торговой гильдии',
  count: 4,
  element: (
    <>
      <div>Купцы и торговцы, которым нравится дополнять оркестр войны звоном чеканной монеты.</div>
    </>
  ),
}