import { AccordionBlock, Chapter } from '../../../components';

import ar01 from '../../../assets/rules/projectile/ar01.jpg';
import ar02 from '../../../assets/rules/projectile/ar02.jpg';
import ar03 from '../../../assets/rules/projectile/ar03.jpg';
import ar04 from '../../../assets/rules/projectile/ar04.jpg';

import s from './general.module.css';

export function General(): JSX.Element {
  return (
    <div className={s.container}>
      <Chapter chapter='БАЗОВЫЕ ПРАВИЛА' />

      <div className={s.label}>Основные принципы мероприятия</div>
      <div className={s.listContainer}>
        <div><b>Безопасность: </b>любое игровое правило может быть нарушено, если оно противоречит безопасности игрока.</div>
        <div><b>Никакого мусора: </b> организаторы принимают полигон чистым и должны сдать его таким же, старайтесь не оставлять после себя мусор.</div>
        <div><b>Театральность: </b> в течении мероприятия старайтесь не выходить из роли без веской на то причины.</div>
        <div><b>Решайте споры немедля: </b>какая бы спорная ситуация на игре не была - постарайтесь ее решить словами сразу же на месте, не откладывайте на потом.</div>
        <div><b>Зрелость: </b>мероприятие подразумевает монстров, жестокость, и походные условия. Поэтому на мероприятие допускаются люди 18+ или младше под ответственность взрослых.</div>
      </div>

      <AccordionBlock
        label="Допуски снаряжения"
        items={[
          {
            label: 'Оружие ближнего боя',
            element: (
              <>
                <div className={s.label}><i>Технические требования</i></div>
                <ol className={s.listContainer}>
                  <li>Боевая часть протектированного оружия (далее ПО) должна быть полностью мягкой.</li>
                  <li>На любом участке боевых частей оружия твёрдые детали внутреннего каркаса не должны прощупываться при нажатии на поверхность ПО.</li>
                  <li>Рекомендуемая максимальная твёрдость поверхности ПО на любом участке боевой части оружия – 30А по Шору. На древках инерционного оружия, кромках и поверхности щитов – 35А по Шору.</li>
                  <li>Максимальный вес оружия в соотношении с его длиной – 800 грамм на метр длины.</li>
                  <li>Диаметр кончика наконечника пики и копья должен быть не менее 35 мм. Толщина мягкого слоя на кончике должна быть не менее 25 мм. При этом наконечник должен быть изготовлен из таких материалов и устроен и таким образом, чтобы даже при сильных колющих ударах его кончик не «падал» в сторону, а сминался, возвращаясь в первоначальное состояние после снятия нагрузки.</li>
                </ol>

                <div className={s.label}><i>Размеры</i></div>
                <div className={s.listContainer}>
                  <li><b>Клинковое оружие</b>: не более 2 м.</li>
                  <li><b>Инерционное оружие</b>: не более 1,6 м.</li>
                  <li><b>Древковое рубящее оружие</b>: не более 2,3 м.</li>
                  <li><b>Пики</b>: не более 3,5 м.</li>
                </div>
              </>
            ),
          },
          {
            label: 'Щиты',
            element: (
              <>
                <div className={s.label}><i>Технические требования</i></div>
                <ol className={s.listContainer}>
                  <li>Щиты должны быть мягкими, лёгкими и прочными. На тыльной стороне щита не должно быть торчащих болтов, шурупов и прочих деталей крепежа, которые могут нанести травму или повредить оружие.</li>
                  <li>К игре допускаются только щиты, полностью протектированные с наружной стороны: плоскость, кромки и умбон (если он есть). Внутренняя стороны щита может быть не протектированной.</li>
                  <li>Толщина протектирующего слоя на кромках щита должна быть не менее 2 см. При этом даже при сильном нажатии пальцем на протектирующий слой кромки щита твёрдый внутренний каркас щита не должен прощупываться.</li>
                  <li>Толщина протектирующего слоя на плоскости щита должна быть не менее 8 мм.</li>
                  <li>Умбон должен быть полностью мягким: изготовленным из ЭП или пустотелым из мягкой резины.</li>
                  <li>Масса щита не должна превышать 6 кг на 1 м2 защитной поверхности щита.</li>
                </ol>

                <div className={s.label}><i>Рекомендованные пропорции</i></div>
                <div className={s.listContainer}>
                  <li><b>Большой щит:</b>  должен вписываться в прямоугольник 140 см в высоту и 70 см в ширину.</li>
                  <li><b>Обычный щит:</b> должен вписываться в прямоугольник 80 см в высоту и 70 см в ширину или круг диаметром 80 см</li>
                  <li><b>Малый щит:</b> должен вписываться в прямоугольник 50 см в высоту и 40 см в ширину или круг диаметром 50 см</li>
                </div>
              </>
            ),
          },
          {
            label: 'Дистанционное оружие',
            element: (
              <>
                <div className={s.label}><i>Технические требования к оружию</i></div>
                <ol className={s.listContainer}>
                  <li>На игру допускаются луки с натяжением до 14 кг (=31 фунт) и скоростью полета стрелы не более 33 м/с (=110 fps). При допуске лук будет натягиваться на всю длину стрелы, до гуманизатора. Ограничители хода тетивы при замерах не учитываются. Изменение длинны тетивы не допускается. Луки со стрелами длиннее 75см до гуманизатора являются объектом особого внимания, с возможным пристрастием при допуске.</li>
                  <li>На игру допускаются арбалеты с натяжение до 18 кг (=39 фунтов) при ходе тетивы не более 30 см и скоростью полета болта не более 33 м/с (=110 fps).</li>
                  <li>Не допускаются спортивные луки, луки с “окном” и пистолетной/анатомической рукояткой, блочные луки и арбалеты. Не допускаются луки и арбалеты, имевшие поломку, которая не была полностью устранена.</li>
                </ol>

                <div className={s.label}><i>Технические требования к снаряду</i></div>
                <ol className={s.listContainer}>
                  <li>На игру допускаются ТОЛЬКО СЕРИЙНЫЕ ГУМАНИЗАТОРЫ диаметра 50мм и более показанные далее (оригинальные, а не такой же формы, изготовленные самостоятельно). Гуманизатор должен быть прочно зафиксирован на древке, с упором в закладной элемент, исключающим соскальзывание древка.</li>
                  <div className={s.arrows}>
                    <img src={ar01} alt='Arrow' height='200' />
                    <img src={ar02} alt='Arrow' height='200' />
                    <img src={ar03} alt='Arrow' height='200' />
                    <img src={ar04} alt='Arrow' height='200' />
                  </div>
                  <li>Древки игровых стрел и болтов должны быть ровными и иметь в поперечном сечении круглую форму, не иметь заноз, трещин, сколов и заусениц.
                    Допускаются деревянные древки не тоньше 8мм или фибергласовые (НЕ карбоновые) не тоньше 6,7мм.
                    Игровые стрелы и болты должны быть с эстетичным и прочным “оперением” без режущих кромок
                    (т.е. стрелы с оперениями из обрезков пластиковых бутылок и т.п. допущены не будут),
                    иметь нормальный баланс (центр тяжести не дальше 1/3 длинны от наконечника)
                    и весить не больше 67 грамм. Болты должны иметь хотя бы парное оперение.
                    Стрелы должны иметь тройное (или большее) оперение и быть не короче 65см.
                    Древки под резьбовые гуманизаторы должны быть укреплены в месте возможного выламывания инсерта.
                    Не допускаются стрелы и болты с древками имевшими поломку.</li>
                </ol>

                <div className={s.label}><i>Техника безопасности</i></div>
                <ol className={s.listContainer}>
                  <li>Стрелковое оружие допускается комплексно (лук+стрелы, арбалет+болты), пользоваться чужими стрелами/болтами запрещено.</li>
                  <li>Использование сломанного гуманизатора (стрела/болт на тетиве или в колчане) приравнивается к нанесению травмы.</li>
                  <li>СТРЕЛЬБА В УПОР ЗАПРЕЩЕНА!</li>
                </ol>

                <div className={s.label}><i>Не допускаются</i></div>
                <div className={s.listContainer}>
                  <li>Ручное огнестрельное оружие.</li>
                  <li>Арбалеты со снарядами в виде “камней”.</li>
                  <li>Оружие на базе NERF и его аналоги.</li>
                  <li>Рогатки, пращи, метательные копья, дротики.</li>
                </div>
              </>
            ),
          },
          {
            label: 'Тяжелое огнестрельное оружие',
            element: (
              <>
                <div className={s.label}><i>Технические требования к оружию</i></div>
                <ol className={s.listContainer}>
                  <li>Огнестрельные орудия имитируются метательными устройствами, выбрасывающими снаряд за счёт энергии детонации петард заводского изготовления
                    (заряжаемых с казны) либо патрона светозвукового действия.
                    Образцы, предполагающие последовательное заряжание в ствол перед выстрелом зажжённой петарды и снаряда не допускаются.</li>
                  <li>Максимальная мощность заряда модели пушки, работающей на детонации пиротехнических составов, ограничена изделием типа «Корсар-5» (рекомендуется “Корсар-4”) и строго аналогичными ему по свойствам.
                    Модификация петард с целью усиления мощности их разрыва категорически запрещена. Мощность устройств, работающих за счёт энергии детонации патронов светозвукового действия ограничена патроном 7,62x54r</li>
                  <li>Не допускаются для инициации выстрела любые другие снаряженные патроны, в том числе строительные.</li>
                  <li>Диаметр канала трубки, имитирующей ствол пушки, ограничен. Минимальный диаметр 64 мм.</li>
                  <li>Каждое огнестрельное орудие должно быть снабжено лафетом. Орудие, снятое с лафета к выстрелу не пригодно.</li>
                  <li>Полевые орудия – пушки, установленные на колёсном лафете. Минимальная высота оси крепления ствола орудия с колёсным лафетом от земли 60 сантиметров. Иные варианты лафета для полевых орудий оговариваются индивидуально.</li>
                  <li>Только данный вид вооружений может снимать очки прочности с городских ворот.</li>
                </ol>

                <div className={s.label}><i>Технические требования к снаряду</i></div>
                <ol className={s.listContainer}>
                  <li>В качестве снаряда допускаются к использованию окрашенные в чёрный, стальной или серый цвет
                    теннисные мячи с внутренним давлением для грунтового и травяного покрытия (мягкие) с максимальным весом 57гр.
                    Такой мяч должен иметь прорезь не менее 20 мм длинной. Использование в качестве моделей снарядов мячей,
                    выпущенных заводским способом без внутреннего давления или предназначенных для покрытий типа «hard» недопустимо.
                    Не окрашенными мячами стрелять запрещено.</li>
                    <li>Допускаются к использованию в качестве снарядов иные мячи (фомовые, резиновые) заводского изготовления большего диаметра,
                      отвечающие следующим требованиям по безопасности: прочность и термоустойчивость.
                      Контрольный замер этих параметров будет осуществляться МГ при допуске орудия в игру.</li>
                </ol>
              </>
            ),
          },
          {
            label: 'Доспехи',
            element: (
              <>
                <div className={s.label}><i>Технические требования</i></div>
                <div className={s.listContainer}>
                  <li>Латные элементы могут быть изготовлены из стали, латуни и бронзы, а так же качественно окрашенных под них алюминиевых сплавов, пластика, толстой кожи и пенополимеров.
                    Использование неокрашенных пластиковых элементов, моделирующих латные, на данной игре ЗАПРЕЩЕНО.</li>
                  <li>Бригантные доспехи обязательно должны иметь в своей основе твёрдые пластины, материал которых не регламентирован.</li>
                  <li>Для изготовления особо массивных доспехов допустимо использование эластичных пенополимеров, с качественным покрасом.</li>
                  <li>Все элементы доспехов и шлемов должны быть изготовлены так, чтобы ими нельзя было нанести травму себе и другим игрокам, а также повредить протектированное оружие.</li>
                  <li>Недопустимы к использованию латные (твёрдые) элементы доспехов с выступающими более чем на 1 см поверхностями малой (менее 3мм) толщины. Все края доспехов, изготовленных из металла малой толщины (менее 1,5 мм) должны быть отбортованы (отбортовка не менее 2 мм толщиной.</li>
                  <li>Кольчужные кольца не должны иметь опасных заусениц.</li>
                  <li>Шипы, рога, нашлемные фигуры и т.п. элементы доспехов и шлемов должны быть мягкими и безопасными.</li>
                </div>
              </>
            ),
          },

        ]}
      />

      <AccordionBlock
            label="Игровые зоны"
            items={[
              {
                label: 'Мертвяк',
                element: (
                  <>
                    <div className={s.label}>Для людей</div>
                    <div>Мертвяк находится в стартовой локации игрока типа <b>"Лагерь"</b> или <b>"Город"</b>.</div>
                    <div>Любой мертвый игрок находящийся в мертвяке должен сдать интенданту или мастеру мертвяка свой паспорт игрока на время пребывания в мертвяке.</div>
                    <div>Фиксация времени проведения в мертвяке ведется интендантом лагеря И самим игроком.</div>
                    <div>Стандартное время пребывания в мертвяке <b>2 часа</b>.</div>
                    <div><i>Основное</i></div>
                    <div className={s.listContainer}>
                      <li>Все время пребывания в мертвяке игрок носит повязку <b>"Мертв"</b>.</li>
                      <li>Все накопленные игровые ценности передаются интенданту.</li>
                      <li>Если в паспорте игрока сорвана наклейка смерти персонажа, то игрок возвращается новым персонажем.  Если наклейка не сорвана, то игрок, после отсидки положенного срока мертвяка, возвращается тем же персонажем, но не помнит ничего, что касалось его «временной» смерти.</li>
                      <li>Запрещено покидать свой лагерь во время пребывания в мертвяке.</li>
                      <li>В случае боевой ситуации в лагере падает замертво, или садится на колено явно давая понять, что мертв.</li>
                    </div>
                    <br />
                    <div>Для нормальной работы мертвяка в лагере рекомендуется:</div>
                    <ol className={s.listContainer}>
                      <li>В неочевидном месте повесить часы в лагере.</li>
                      <li>Установить табличку где будет указано время когда и кому возвращаться в игру (таблица должна быть доступна любому игроку команды).</li>
                    </ol>
                    <br />
                    <div>После отбытия мертвяка игрок возвращается в игру с полностью восстановленным здоровьем и броневыми хитами.</div>
                  </>
                ),
              },
              {
                label: 'Штурмовая зона',
                element: (
                  <>
                    <div>Специально оборудованное место для боестолкновения возле лагеря.</div>
                    <br />
                    <div><i>Основное</i></div>
                    <ol className={s.listContainer}>
                      <li>Контролирует выполнение правил штурма интендант обороняющегося лагеря или мастер по боевым взаимодействиям.</li>
                      <li>Игроки не должны мешать или препятствовать работе интенданта.</li>
                      <li>Любые указания интенданта во время штурма считаются приоритетными.</li>
                      <li>Все спорные ситуации выясняются сразу ПОСЛЕ окончания штурма.</li>
                      <li>После начала штурма любое внешнее взаимодействие с участниками штурма не засчитывается.</li>
                    </ol>
                    <br/>
                    <div><i>Дополнительно</i></div>
                    <div className={s.listContainer}>
                      <li>После начала штурма нельзя присоединится к штурмующим извне, но можно дождаться его окончания и присоединится позже.</li>
                      <li>Снаряд попавший в участника штурма, прилетевший извне штурмовой зоны, не наносит ему урон.</li>
                      <li>Любой бежавший боец (вышел за границы штурмуемой зоны) до окончания штурма считается мертвым.</li>
                      <li>Сдавшийся боец выбывает из боевого штурма, но мертвым не считается.</li>
                      <li>Штурм завершается сигналом интенданта и указанием кто одержал победу.</li>
                    </div>
                  </>
                ),
              },
              {
                label: 'Пожизняк',
                element: (
                  <>
                    <div>Специальная зона возле игрового лагеря типа <b>"Город"</b> или <b>"Лагерь"</b>, где размещены туристические стоянки игроков. Является не игровой зоной.</div>
                    <div className={s.label}><i>Требования</i></div>
                    <ol className={s.listContainer}>
                      <li>Палатки пожизняка не должны быть видны из лагеря.</li>
                      <li>Вход в пожизняк должен иметь табличку и на вечер - подсветку.</li>
                      <li>В пожизняке запрещено разводить костры.</li>
                      <li>Пожизняк должен быть оборудован мусоркой.</li>
                      <li>После завершения игры в зоне пожизняка не должно оставаться мусора после игроков (он выносится игроками в указанную мастерами точку).</li>
                    </ol>

                    <div className={s.label}><i>Запрещено</i></div>
                    <ol className={s.listContainer}>
                      <li>Прятаться в пожизняке от игровых ситуаций.</li>
                      <li>Проходить через пожизняк игровым персонажем с целью сократить дистанцию или обойти препятствия.</li>
                      <li>Прятать игровые предметы в пожизняке.</li>
                    </ol>
                  </>
                ),
              },
              {
                label: 'Стоянка',
                element: (
                  <>
                    <div>На полигоне будет оборудована отдельная стоянка под автомобили. Эта зона считается не игровой и приравнена к статусу <b>"Пожизняка"</b>. Кататься по полигону на машинах в течении игры строжайше запрещено.</div>
                    <br />
                    <div className={s.label}><i>Дополнительно</i></div>
                    <ol className={s.listContainer}>
                      <li>Под лобовым стеклом разместите номер телефона водителя или ФИО водителя, по которому его можно найти.</li>
                      <li>При установке машины на стоянку передайте номер автомобиля и телефон водителя мастерам.</li>
                    </ol>
                  </>
                ),
              },
              {
                label: 'Санитарная зона',
                element: (
                  <div>Туалеты и души не являются игровыми зонами. Для безопасного посещения игрокам разрешается надеть повязку <b>"Мертв"</b> для посещения санитарной зоны. Если игрок использует повязку <b>"Мертв"</b> - он обязан кротчайшим путем проследовать в санитарную зону без контактов с другими игроками.</div>
                ),
              },
            ]}
          />
    </div>
  )
}