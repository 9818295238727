import { Item } from '../type';

import s from './actions.module.css';

export const gateRepair: Item = {
  label: 'Ремонт городских ворот',
  element: (
    <>
      <div>Чтобы повысить прочность ворот, необходимо использовать ресурсы <b>«Железная руда»</b> и <b>«Ремкомплект»</b>.</div>
      <div className={s.label}><i>Формула</i></div>
      <div>
        <div>Количество ресурсов <b>"Железная руда"</b> = X - Y</div>
        <div>Количество ресурсов <b>"Ремкомплект"</b> = X - Y</div>
        <div>где</div>
        <div>X - это желаемое количество очков прочности (которое вы хотите получить после ремонта)</div>
        <div>Y - Текущее количество очков прочности</div>
      </div>
      <div>Для ремонта требуется использовать оба вида ресурсов.</div>

      <div className={s.label}>Пример</div>
      <i>
        <div>Максимальное количество очков прочности ворот = 10</div>
        <div>Текущее количество очков прочности = 5</div>
        <div>Жители города хотят повысить очки прочности городских ворот до 8 единиц.</div>
        <br/>
        <div>Решение</div>
        <div>Желаемая прочность минус текущая прочность = 8-5 = 3</div>
        <div>Итого для поднятия желаемой прочности требуется 3 ресурса <b>«Железная руда»</b> и 3 ресурса <b>«Ремкомплект»</b>.</div>
      </i>

      <div className={s.label}>Механика</div>
      <ol className={s.listContainer}>
        <li>Игроки находят мастера.</li>
        <li>Четко и внятно заявляют какую прочности они хотят получить.</li>
        <li>Передают необходимые ресурсы.</li>
        <li>Ремонт длиться 30 минут.</li>
        <li>Если городские ворота получили урон из осадной техники в течении ремонта, то ремонт обнуляется.</li>
      </ol>
    </>
  ),
}