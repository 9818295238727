import { Item } from '../type';

import s from './actions.module.css';

export const buildRepair: Item = {
  label: 'Ремонт строения',
  element: (
    <>
      <div>Игроки могут восстановить постройку.</div>

      <div className={s.label}><i>Механика</i></div>
      <ol className={s.listContainer}>
        <li>Игрок находит мастера или интенданта.</li>
        <li>Игрок передает интенданту ресурс «Ремкомплект» по формуле: желаемый уровень локации – текущий уровень локации = количество <b>«Ремкомплектов»</b>.</li>
        <li>Игрок отыгрывает ремонт строения минимум 15 минут.</li>
      </ol>

      <div className={s.label}><i>Дополнительно</i></div>
      <ol className={s.listContainer}>
        <li>Нельзя разрушить постройку, у которой нет звезд качества.</li>
        <li>Отремонтировать выше стартового качества локации нельзя.</li>
        <li>Мастер может отказать в проведении восстановительных работ если у игроков нет соответствующего антуража (пилы, топоры, лопаты, молотки и т.д.).</li>
      </ol>

      <div className={s.label}><i>Пример</i></div>
      <div>
        <div><i>Дано:</i></div>
        <div>Желаемый уровень = 4</div>
        <div>Текущий уровень локации = 2</div>
      </div>

      <div>
        <div><i>Решение:</i></div>
        <div>4 (желаемый уровень локации) – 2 (текущий уровень локации) = 2 «Ремкомплекта»</div>
      </div>

      <div>
        <div><i>Ответ:</i></div>
        <div>Игрокам надо сдать 2 «Ремкомплекта» для того что бы полностью отремонтировать локацию</div>
      </div>
    </>
  ),
}