import { Item } from '../type';

import s from './actions.module.css';

export const repair: Item = {
  label: 'Ремонт',
  element: (
    <>
      <div className={s.label}>Ремонт в кузнице</div>
      <div>Кузнец производит ремонт снаряжения персонажа.</div>
      <ol className={s.listContainer}>
        <li>Игрок переходит в статус <b>«Ремонт»</b>.</li>
        <li>Поломанный игрок должен пробыть <b>15 минут</b> возле кузницы, держа при себе печать «Ремонт». На игрока в это время действует травма <b>«Истощение»</b>.</li>
        <li>Игрок восстанавливает все броневые хиты.</li>
        <li>Игрок возвращает кузнецу печать «Ремонт».</li>
        <li>Игрок выходит из статуса <b>«Ремонт»</b>.</li>
        <li>Травма <b>«Истощение»</b> снимается.</li>
      </ol>
      <div>Примечание: Ресурсы при этом не расходуются. Количество одновременно ремонтируемых ограничено количеством верстаков. Печати <b>«Ремонт»</b> выдаются госпиталю организаторами при регистрации локации</div>

      <div className={s.label}>Ремонт <b>«Ремкомплектом»</b></div>
      <div>Персонаж использует подготовленный комплект для ремонта амуниции.</div>
      <ol className={s.listContainer}>
        <li>Карта <b>«Ремкомплект»</b> разрывается.</li>
        <li>Игрок переходит в статус <b>«Ремонт»</b>.</li>
        <li>Игрок ожидает <b>15 минут</b>, держа при себе примененную карту <b>«Ремкомплект»</b>. На игрока в это время действует травма <b>«Истощение»</b>.</li>
        <li>Игрок восстанавливает все броневые хиты.</li>
        <li>Игрок выходит из статуса <b>«Ремонт»</b>.</li>
        <li>Игрок выбрасывает в мусор использованную карту <b>«Ремкомплекта»</b>.</li>
        <li>Травма <b>«Истощение»</b> снимается.</li>
      </ol>

      <div className={s.label}>Ремонт <b>«Оружейным маслом»</b></div>
      <div>Персонаж ремонтирует свое снаряжение благодаря алхимическому маслу.</div>
      <ol className={s.listContainer}>
        <li>Карта <b>«Оружейное масло»</b> разрывается.</li>
        <li>Персонаж восстанавливает все броневые хиты</li>
      </ol>
      <div><b>ПРИМЕЧАНИЕ:</b> Нельзя использовать во время активной фазы боя и при штурмах.</div>

      <div className={s.label}>Условия срыва ремонта</div>
      <div className={s.listContainer}>
        <li><b>Получение урона:</b> Если игрок получил урон, когда пребывал в состоянии <b>«Ремонт»</b>.</li>
        <li><b>Плохая закалка:</b>  Игрок значительно удалился от кузницы. Игрок прерывает ремонт и ему придется начинать его сначала.</li>
      </div>
      <div><b>ПРИМЕЧАНИЕ:</b> После срыва ремонта ресурсы ив время затраченное на него не восстанавливаются</div>
    </>
  ),
}