import { Item } from '../rules-text/type';

import isCoat from '../assets/coats/is.png';

import s from './history.module.css';

export const isKingdomHistory: Item = {
  label: 'Княжество Ис',
  element: (
    <>
      <div className={s.info}>
        <div className={s.coat}>
          <img src={isCoat} alt='' />
        </div>

        <div className={s.text}>
          <div><i>Правитель: </i>князь Кьярген Дайнманнсон</div>
          <div><i>Столица: </i>город Шорк</div>
          <div><i>Герб: </i>Корабль на синем фоне</div>
          <div><i>Цвета: </i>Любые клановые</div>
        </div>
      </div>

      <div className={s.block2}>
        <h3>Краткая история</h3>
        <div>Княжество Ис является языческим государством с самым крупным флотом, который активно устраивает набеги на западное побережье континента</div>
        <br/>
        <div>Имеет торговое соглашение с содружеством Рандель</div>
        <div>Никогда не входило в состав империи Гейзериха</div>
        <div>Легенда гласит, что когда первые корабли княжества высадили свой десант на месте текущего города Флепорт, то у гостей спросили, какое государство они представляют</div>
        <div>На что чужаки ответили на ломанном общем языке: "Мы есть море, мы есть корабль". С тех пор во всех летописях континента княжество Ис имеет герб в виде корабля на синем фоне</div>
      </div>
    </>
  ),
}